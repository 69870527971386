import React from 'react';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { Box, Grid } from '@mui/material';

import { useEmploymentTypeSelectItems } from '../../../../../hooks';

import { InputFieldFactory } from '../../../../../components/FormikField';
import { TranslationKeys } from '../../../../../translations';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';

type IncomeStepProps = {
  employeeTypes: Array<Record<string, any>>;
  onSubmit: () => Promise<void>;
  stepState: Record<string, any>;
  loading?: boolean;
};

export const IncomeStep = React.forwardRef(
  ({ employeeTypes, onSubmit, stepState, loading = false }: IncomeStepProps, ref) => {
    const formRef = React.createRef<FormikProps<any>>();
    const { employmentTypeSelectItems } = useEmploymentTypeSelectItems();
    const intl = useIntl();

    React.useImperativeHandle(
      ref,
      () => ({
        submit() {
          formRef?.current?.submitForm();
        },
        getState() {
          return {
            form: formRef?.current?.values,
          };
        },
      }),
      [formRef],
    );

    const fields = React.useMemo(
      () => [
        {
          name: 'employee.employment.startDate',
          type: 'date',
          required: true,
          header: TranslationKeys.employees_detail_employmentStart,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_employmentStart }),
          loading: loading,
          schema: (schema: any) => schema.required(),
        },
        {
          name: 'employee.employment.yearsFrom',
          type: 'date',
          required: false,
          header: TranslationKeys.employees_detail_employmentYearsFrom,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_employmentYearsFrom }),
          loading: loading,
          schema: (schema: any) =>
            schema.max(
              endOfDay(new Date()),
              intl.formatMessage({
                id: TranslationKeys.validationMessage_date_maxToday,
              }),
            ),
        },
        {
          name: 'employee.employment.endDate',
          type: 'date',
          required: false,
          header: TranslationKeys.employees_detail_employmentEnd,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_employmentEnd }),
          loading: loading,
        },
        {
          name: 'employee.employment.wage.grossWage',
          type: 'number',
          required: true,
          header: TranslationKeys.employees_detail_monthlyGrossWage,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_monthlyGrossWage }),
          loading: loading,
          options: {
            style: 'currency',
            currency: 'EUR',
          },
          schema: (schema: any) => schema.positive().required(),
        },
        {
          name: 'employee.employment.partTimePercentage',
          type: 'number',
          required: true,
          header: TranslationKeys.events_content_header_partTimePercentage,
          placeholder: intl.formatMessage({ id: TranslationKeys.events_content_header_partTimePercentage }),
          loading: loading,
          options: {
            style: 'percent',
            openScale: true,
            min: 0,
            max: 100,
          },
          schema: (schema: any) => schema.positive().required(),
        },
        {
          name: 'employee.employeeTypeId',
          type: 'autocomplete',
          required: false,
          header: TranslationKeys.employees_detail_employeeType,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_employeeType }),
          loading: loading,
          items: (employeeTypes || []).map(type => {
            return {
              element: type.name,
              value: type.employeeTypeId || null,
            };
          }),
        },
        {
          name: 'employee.employment.employmentType',
          type: 'autocomplete',
          required: false,
          header: TranslationKeys.employees_detail_employmentType,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_employmentType }),
          loading: loading,
          items: employmentTypeSelectItems,
        },
        {
          name: 'employee.costCenter',
          type: 'text',
          required: false,
          header: TranslationKeys.global_costCenter,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_costCenter }),
          loading: loading,
        },
      ],
      [intl, loading, employeeTypes, employmentTypeSelectItems],
    );

    const initialValues = React.useMemo(() => {
      return {
        employee: {
          employment: {
            startDate: null,
            yearsFrom: null,
            endDate: null,
            partTimePercentage: null,
            wage: {
              grossWage: null,
            },
          },
          employeeTypeId: null,
          ...(stepState.form.employee || {}),
        },
      };
    }, [stepState.form]);

    const handleEmploymentStartDateChange = (field: any) => {
      const employmentStartDate = startOfDay(new Date(field.value));
      const today = startOfDay(new Date());

      if (
        (formRef?.current?.values?.employee?.employment?.yearsFrom === null ||
          formRef?.current?.values?.employee?.employment?.yearsFrom === '') &&
        field.value !== ''
      ) {
        if (employmentStartDate.getTime() <= today.getTime()) {
          field.setFieldValue('employee.employment.yearsFrom', startOfDay(new Date(field.value)));
          return;
        }

        field.setFieldValue('employee.employment.yearsFrom', startOfDay(new Date()));
      }
    };

    return (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
        {() => (
          <>
            <Grid container spacing={2}>
              {fields.map((field, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <InputFieldFactory
                      {...(field.name === 'employee.employment.startDate' && {
                        onChange: handleEmploymentStartDateChange,
                      })}
                      field={field}
                    />
                  </Grid>
                );
              })}
            </Grid>
            <Box p={1} />
          </>
        )}
      </Formik>
    );
  },
);

IncomeStep.displayName = 'IncomeStep';
