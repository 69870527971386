import React from 'react';
import type { FormikProps } from 'formik';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';

import { Box, Grid } from '@mui/material';

import { TranslationKeys } from '../../../../../translations';

import type { CivilStatusEnum } from '../../../../../types/civilStatus.types';

import { useGenderSelectItems } from '../../../../../hooks/useGenderSelectItems';
import { useCivilStatusSelectItems } from '../../../../../hooks/useCivilStatusSelectItems';

import { InputFieldFactory } from '../../../../../components/FormikField';
import { GenderStatusEnum } from '../../../../../types';

type PersonalInfoStepProps = {
  employeeCivilStatus: CivilStatusEnum;
  onSubmit: () => Promise<void>;
  stepState: Record<string, any>;
  loading?: boolean;
};

export const PersonalInfoStep = React.forwardRef(
  ({ onSubmit, stepState, loading = false }: PersonalInfoStepProps, ref) => {
    const formRef = React.createRef<FormikProps<any>>();
    const { genderSelectItems } = useGenderSelectItems();
    const { civilStatusSelectItems } = useCivilStatusSelectItems();
    const intl = useIntl();

    React.useImperativeHandle(
      ref,
      () => ({
        submit() {
          formRef?.current?.submitForm();
        },
        getState() {
          return {
            form: formRef?.current?.values,
          };
        },
      }),
      [formRef],
    );

    const fields = React.useMemo(
      () => [
        {
          name: 'employee.personnelNumber',
          type: 'text',
          required: true,
          header: TranslationKeys.events_content_header_personnelNumber,
          placeholder: intl.formatMessage({ id: TranslationKeys.events_content_header_personnelNumber }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(12).required(),
        },
        {
          name: 'employee.socialSecurityNumber',
          type: 'text',
          required: true,
          header: TranslationKeys.social_security_number,
          placeholder: intl.formatMessage({ id: TranslationKeys.social_security_number }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(12).required(),
        },
        {
          name: 'employee.personName.initials',
          type: 'text',
          required: true,
          header: TranslationKeys.global_initials,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_initials }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(255).required(),
        },
        {
          name: 'employee.personName.firstName',
          type: 'text',
          required: false,
          header: TranslationKeys.global_firstName,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_firstName }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(255),
        },
        {
          name: 'employee.personName.lastNamePrefix',
          type: 'text',
          required: false,
          header: TranslationKeys.global_lastNamePrefix,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_lastNamePrefix }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(12),
        },
        {
          name: 'employee.personName.lastName',
          type: 'text',
          required: true,
          header: TranslationKeys.global_lastName,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_lastName }),
          loading: loading,
          schema: (schema: any) => schema.trim().strict().max(255).required(),
        },
        {
          name: 'employee.gender',
          type: 'autocomplete',
          required: true,
          header: TranslationKeys.global_gender,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_gender }),
          loading: loading,
          items: genderSelectItems.filter(item => item.value !== GenderStatusEnum.Unknown),
        },
        {
          name: 'employee.dateOfBirth',
          type: 'date',
          required: true,
          header: TranslationKeys.employees_detail_dateOfBirth,
          placeholder: intl.formatMessage({ id: TranslationKeys.employees_detail_dateOfBirth }),
          loading: loading,
        },
        {
          name: 'employee.civilStatus',
          type: 'autocomplete',
          required: true,
          header: TranslationKeys.global_civilStatus,
          placeholder: intl.formatMessage({ id: TranslationKeys.global_civilStatus }),
          loading: loading,
          items: civilStatusSelectItems,
        },
      ],
      [civilStatusSelectItems, genderSelectItems, intl, loading],
    );

    const initialValues = React.useMemo(() => {
      return {
        employee: {
          personnelNumber: null,
          socialSecurityNumber: null,
          personName: {
            firstName: null,
            lastNamePrefix: null,
            lastName: null,
          },
          gender: null,
          dateOfBirth: null,
          civilStatus: null,
          ...(stepState.form.employee || {}),
        },
      };
    }, [stepState.form]);

    return (
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} innerRef={formRef}>
        {() => (
          <>
            <Grid container spacing={2}>
              {fields.map((field, index) => {
                return (
                  <Grid item xs={4} key={index}>
                    <InputFieldFactory field={field} />
                  </Grid>
                );
              })}
            </Grid>
            <Box p={1} />
          </>
        )}
      </Formik>
    );
  },
);

PersonalInfoStep.displayName = 'PersonalInfoStep';
