import { useMutation } from '@tanstack/react-query';
import ConnectionService from '../../../Domain/Connections/Services/ConnectionService';

export type employeeData = {
  socialSecurityNumber: string;
  employmentStartDate: Date;
  annualWage: number;
  partTimePercentage: number;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  gender: string | null;
  emailAddress: string | null;
  civilStatus: string | null;
  personnelNumber: string | null;
};

type UploadInsurerData = {
  connectionId: string;
  batchId: string;
  employeeData: employeeData[];
};

type UploadInsurerFileResponseData = {
  taskId: string;
  moduleType: string;
};

const mutationFn = async (data: UploadInsurerData) => {
  const response = await ConnectionService.uploadInsurerData(data);
  return response.data;
};

export const useConnectionUploadInsurerDataMutation = () => {
  return useMutation<UploadInsurerFileResponseData, unknown, UploadInsurerData>(mutationFn);
};
