export const COMPARE_BATCH_TEMPLATE_FILE_NAME = 'Compare_Batch_Template';

export const COMPARE_BATCH_FILE_STRUCTURE: Array<{
  headerTitle: string;
  fieldName: string;
}> = [
  {
    headerTitle: 'employee.name_first',
    fieldName: 'employee.name_first',
  },
  {
    headerTitle: 'employee.name_prefix',
    fieldName: 'employee.name_prefix',
  },
  {
    headerTitle: 'employee.name_last',
    fieldName: 'employee.name_last',
  },
  {
    headerTitle: 'employee.date_birth',
    fieldName: 'employee.date_birth',
  },
  {
    headerTitle: 'employee.gender',
    fieldName: 'employee.gender',
  },
  {
    headerTitle: 'employee.email',
    fieldName: 'employee.email',
  },
  {
    headerTitle: 'employee.bsn',
    fieldName: 'employee.bsn',
  },
  {
    headerTitle: 'employee.civil_status',
    fieldName: 'employee.civil_status',
  },
  {
    headerTitle: 'employee.number',
    fieldName: 'employee.number',
  },
  {
    headerTitle: 'employment.start',
    fieldName: 'employment.start',
  },
  {
    headerTitle: 'employment.wage',
    fieldName: 'employment.wage',
  },
  {
    headerTitle: 'employment.parttime',
    fieldName: 'employment.parttime',
  },
];

export const COMPARE_BATCH_FILE_HEADERS = COMPARE_BATCH_FILE_STRUCTURE.map(fileStructure => fileStructure.headerTitle);
