import axios from 'axios';

let getMutationsToken;

export const getMutationsForBatch = async (
  connectionId,
  batchMongoId,
  page,
  perPage,
  sortBy,
  searchQuery,
  mutationType,
  useToken,
) => {
  try {
    if (useToken && typeof getMutationsToken !== typeof undefined) {
      getMutationsToken.cancel('Operation canceled due to new request.');
    }

    if (useToken) {
      getMutationsToken = axios.CancelToken.source();
    }

    return axios.get(`/connections/${connectionId}/batches/${batchMongoId}/mutations`, {
      cancelToken: useToken ? getMutationsToken.token : null,
      params: {
        page,
        perPage,
        sortBy,
        employeeSearch: searchQuery === '' ? null : searchQuery,
        mutationType: mutationType !== "''" ? mutationType : null,
      },
    });
  } catch (err) {
    if (axios.isCancel(err)) {
      return { cancelPrevQuery: true };
    }

    throw err;
  }
};

/**
 * @param {string} connectionId
 * @param {string} batchId
 * @param {Array.<string>} externalIds
 * @returns {Promise<axios.AxiosResponse<any>>}
 */
export const forceResendMutations = async (connectionId, batchId, externalIds) => {
  return axios.post(`/connections/${connectionId}/module/run`, {
    connectionId: connectionId,
    moduleType: 'insurance_brydge_partial_export_batch_module',
    parameters: {
      batchId: batchId,
      externalIds: externalIds,
      connectionId: connectionId,
    },
  });
};

const mutationsService = { forceResendMutations, getMutationsForBatch };

export default mutationsService;
