import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import type { User } from 'app/types';

import { normalizeFormError } from 'app/utils';

import { TranslationKeys } from 'app/translations';

import type { TableSelectedState } from 'app/store';
import { StoreActions, StoreSelectors, useStoreDispatch, useStoreSelector } from 'app/store';

import {
  useSnakeBar,
  useUserCreateMutation,
  useUserDeleteMutation,
  useUserReset2FaTokenMutation,
} from '../../../../hooks';

import { ConfirmDialog, useBreadcrumb } from 'app/components';

import { UsersToolbar } from '../../Components';

import { DivStyled } from './UserManagementPage.styles';
import { CreateUserDialog, UsersTable } from './components';
import { useRolesQuery, useUsersQuery } from '../../Hooks';

export const UserManagementPage = () => {
  const dispatch = useStoreDispatch();
  const navigate = useNavigate();
  const { showSuccessSnakeBar, showErrorSnakeBar } = useSnakeBar();
  const { mutate: createUserMutation } = useUserCreateMutation();
  const { mutate: deleteUserMutation } = useUserDeleteMutation();
  const { mutate: resetUser2FaTokenMutation } = useUserReset2FaTokenMutation();

  const [showCreateUserDialog, setShowCreateUserDialog] = React.useState(false);
  const [confirmDeleteModal, setConfirmDeleteModal] = React.useState(false);
  const [confirmReset2FaModal, setConfirmReset2FaModal] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState<User['userId'] | null>(null);

  const ownerId = useStoreSelector<string>(state => StoreSelectors.AppSelector.selectOwnerId(state.AppReducer));
  const { page, pageSize, searchQuery } = useStoreSelector<TableSelectedState>(state => state.UsersReducer);

  const {
    data: users,
    isLoading: isLoadingUsersQuery,
    refetch: refetchUsersQuery,
  } = useUsersQuery({
    ownerId,
    page,
    perPage: pageSize,
    searchQuery,
  });

  const { data: roles, isLoading: isLoadingRolesQuery } = useRolesQuery();

  const isLoading = isLoadingUsersQuery || isLoadingRolesQuery;

  const handleSearch = React.useCallback(
    event => {
      dispatch(StoreActions.UsersActions.search(event.target.value, false));
    },
    [dispatch],
  );

  const onPaginationChange = React.useCallback(
    ({ rowSize, page }) => {
      if (typeof rowSize !== 'undefined') {
        dispatch(StoreActions.UsersActions.setPageSize(rowSize, false));
      }
      if (typeof page !== 'undefined') {
        dispatch(StoreActions.UsersActions.changePage(page, false));
      }
    },
    [dispatch],
  );

  const handleCreateUser = React.useCallback(
    (values, form) => {
      const method = 'createUser';

      createUserMutation(values, {
        onSuccess: () => {
          refetchUsersQuery();
          form.resetForm();
          setShowCreateUserDialog(false);
          showSuccessSnakeBar({ method });
          navigate(`/users/${values.userId}`);
        },
        onError: (error: any) => {
          const errors = normalizeFormError(error, form);
          if (!errors.violations) {
            showErrorSnakeBar({ method, message: errors.message });
          }
        },
      });
    },
    [createUserMutation, navigate, refetchUsersQuery, showErrorSnakeBar, showSuccessSnakeBar],
  );

  const onDeleteActionClick = React.useCallback(userId => {
    setConfirmDeleteModal(true);
    setSelectedUserId(userId);
  }, []);

  const deleteUser = React.useCallback(() => {
    if (selectedUserId) {
      const method = 'deleteUser';

      deleteUserMutation(
        { userId: selectedUserId },
        {
          onSuccess: () => {
            refetchUsersQuery();
            setSelectedUserId(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [deleteUserMutation, refetchUsersQuery, showErrorSnakeBar, showSuccessSnakeBar, selectedUserId]);

  const onReset2FaActionClick = React.useCallback(userId => {
    setConfirmReset2FaModal(true);
    setSelectedUserId(userId);
  }, []);

  const resetUser2Fa = React.useCallback(() => {
    if (selectedUserId) {
      const method = 'resetUser2Fa';

      resetUser2FaTokenMutation(
        { userId: selectedUserId },
        {
          onSuccess: () => {
            refetchUsersQuery();
            setSelectedUserId(null);
            showSuccessSnakeBar({ method });
          },
          onError: (error: any) => {
            showErrorSnakeBar({ method, message: error.message });
          },
        },
      );
    }
  }, [refetchUsersQuery, resetUser2FaTokenMutation, selectedUserId, showErrorSnakeBar, showSuccessSnakeBar]);

  useBreadcrumb(TranslationKeys.menu_users, {
    hideTrail: true,
    hideTitle: true,
    otherElements: (
      <UsersToolbar
        handleClickAdd={() => setShowCreateUserDialog(true)}
        handleSearch={handleSearch}
        totalCount={isLoading ? undefined : users?.totalCount}
      />
    ),
  });

  return (
    <>
      <DivStyled>
        <UsersTable
          isLoading={isLoading}
          users={users?.data || []}
          roles={roles?.data || []}
          page={page}
          rowsPerPage={pageSize}
          totalCount={users?.totalCount ?? 0}
          onPaginationChange={onPaginationChange}
          onDeleteActionClick={onDeleteActionClick}
          onReset2FaActionClick={onReset2FaActionClick}
        />
      </DivStyled>

      {showCreateUserDialog && (
        <CreateUserDialog
          show={showCreateUserDialog}
          onSubmit={handleCreateUser}
          onClose={() => setShowCreateUserDialog(false)}
          ownerId={ownerId}
          roles={roles?.data || []}
        />
      )}

      {confirmDeleteModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.users_confirmDeleteTitle} />}
          open={confirmDeleteModal}
          setOpen={setConfirmDeleteModal}
          onConfirm={deleteUser}
        >
          <FormattedMessage id={TranslationKeys.users_confirmDeleteMessage} />
        </ConfirmDialog>
      )}
      {confirmReset2FaModal && (
        <ConfirmDialog
          title={<FormattedMessage id={TranslationKeys.users_reset2FA_confirmationTitle} />}
          open={confirmReset2FaModal}
          setOpen={setConfirmReset2FaModal}
          onConfirm={resetUser2Fa}
        >
          <FormattedMessage id={TranslationKeys.users_reset2FA_confirmationMessage} />
        </ConfirmDialog>
      )}
    </>
  );
};
