import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import type { FormikConfig, FormikProps } from 'formik';
import { Form, Formik } from 'formik';
import { TranslationKeys } from 'app/translations';
import { FileType, HasAccessTo } from 'app/components';
import FileUploadAreaWithTemplate from 'app/components/FileUploadAreaWithTemplate';
import { ListFile } from 'app/components/FileUploadAreaWithTemplate/ListFile';
import { BATCH, CONNECTION } from 'app/common/Authorization/entities';
import { RUN, VIEW } from 'app/common/Authorization/permissions';
import type { Connection } from 'app/types';
import { useConnectionUploadInsurerDataMutation, useSnakeBar } from 'app/hooks';
import {
  COMPARE_BATCH_FILE_HEADERS,
  COMPARE_BATCH_FILE_STRUCTURE,
  COMPARE_BATCH_TEMPLATE_FILE_NAME,
  CompareBatchFileReadStrategy,
} from './utils';
import { DivStyled } from './CompareBatchFileUpload.styles';
import * as XLSX from 'xlsx';

export const COMPARE_BATCH_FILE_UPLOAD_DATA_TEST_ID = 'compare_batch_file_upload_component';

type FormValues = {
  uploadError: boolean;
  file?: File;
};

type CompareBatchFileUploadProps = {
  connectionId: Connection['connectionId'] | undefined;
  batchId: string | undefined;
  isLoading?: boolean;
  startListeningForDataAfterUploadFile: (taskId: string) => void;
};

export const CompareBatchFileUpload = ({
  connectionId,
  batchId,
  startListeningForDataAfterUploadFile,
  isLoading = false,
}: CompareBatchFileUploadProps) => {
  const intl = useIntl();
  const { showErrorSnakeBar } = useSnakeBar();

  const { mutate: uploadInsurerDataMutation, isLoading: isUploading } = useConnectionUploadInsurerDataMutation();

  const canDownloadTemplate = HasAccessTo(BATCH, VIEW);
  const canUploadFile = HasAccessTo(CONNECTION, RUN);

  const formikInitialValues = React.useMemo<FormValues>(() => {
    return {
      uploadError: false,
      file: undefined,
    };
  }, []);

  const parseExcelFile = useCallback(file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = event => {
        try {
          const payload = event.target?.result;
          const workbook = XLSX.read(payload, { type: 'binary', dateNF: 'dd/MM/yyyy' });
          const sheet = workbook.Sheets[Object.keys(workbook.Sheets)[0]];
          const range = XLSX.utils.decode_range(sheet['!ref'] || '');

          range.s.r = 1;
          const employeeData: object[] = XLSX.utils.sheet_to_json(sheet, {
            header: [
              'employee.name_first',
              'employee.name_prefix',
              'employee.name_last',
              'employee.date_birth',
              'employee.gender',
              'employee.email',
              'employee.bsn',
              'employee.civil_status',
              'employee.number',
              'employment.start',
              'employment.wage',
              'employment.parttime',
            ],
            blankrows: false,
            skipHidden: true,
            rawNumbers: true,
            raw: false,
            range: range,
          });

          resolve(employeeData);
        } catch (error) {
          reject(error);
        }
      };
      reader.onerror = () => {
        reject(new Error(`Error occurred reading file: ${file.name}`));
      };
      reader.readAsBinaryString(file);
    });
  }, []);

  const formikOnSubmit = React.useCallback<FormikConfig<FormValues>['onSubmit']>(
    async ({ uploadError, file }) => {
      if (file) {
        await parseExcelFile(file)
          .then(async employeeData => {
            if (!uploadError && connectionId && batchId && employeeData) {
              const method = 'uploadInsurerData';

              uploadInsurerDataMutation(
                {
                  connectionId,
                  batchId,
                  // @ts-ignore
                  employeeData: employeeData.map((employee: { [x: string]: any }) => ({
                    socialSecurityNumber: employee['employee.bsn'],
                    employmentStartDate: new Date(employee['employment.start']),
                    annualWage: parseFloat(employee['employment.wage'] ?? 0),
                    partTimePercentage: parseFloat(employee['employment.parttime'] ?? 0),
                    firstName: employee['employee.name_first'],
                    middleName: employee['employee.name_prefix'],
                    lastName: employee['employee.name_last'],
                    dateOfBirth: employee['employee.date_birth'],
                    gender: employee['employee.gender'],
                    civilStatus: employee['employee.civil_status'],
                  })),
                },
                {
                  onSuccess: data => {
                    startListeningForDataAfterUploadFile(data.taskId);
                  },
                  onError: error => {
                    showErrorSnakeBar({
                      method,
                      message: intl.formatMessage({ id: TranslationKeys.global_uploadFile_error }),
                    });
                    // eslint-disable-next-line no-console
                    console.error(error);
                  },
                },
              );
            }
          })
          .catch(error => {})
          .finally(() => {});
      }
    },
    [
      connectionId,
      batchId,
      intl,
      showErrorSnakeBar,
      startListeningForDataAfterUploadFile,
      uploadInsurerDataMutation,
      parseExcelFile,
    ],
  );

  const initialValues = React.useMemo<Array<{ element: string; value: { fieldName: string } }>>(() => {
    return COMPARE_BATCH_FILE_STRUCTURE.map(fileStructure => ({
      element: fileStructure.headerTitle,
      value: {
        fieldName: fileStructure.fieldName,
      },
    }));
  }, []);

  const onFilesChange = React.useCallback(
    (form: FormikProps<FormValues>) => (files: File[]) => {
      form.setFieldValue('file', files[0]);
    },
    [],
  );

  const onChangeList = React.useCallback(
    (form: FormikProps<FormValues>) => () => {
      form.submitForm();
    },
    [],
  );

  const readFileStrategy = new CompareBatchFileReadStrategy(intl);
  const listFile = new ListFile(COMPARE_BATCH_FILE_HEADERS, readFileStrategy);

  return (
    <Formik<FormValues> initialValues={formikInitialValues} onSubmit={formikOnSubmit}>
      {form => (
        <Form noValidate>
          <DivStyled data-testid={COMPARE_BATCH_FILE_UPLOAD_DATA_TEST_ID}>
            <FileUploadAreaWithTemplate
              title={TranslationKeys.global_download_templateFile}
              fileName={COMPARE_BATCH_TEMPLATE_FILE_NAME}
              allowedFileTypes={[FileType.Csv]}
              initialFiles={[]}
              onFilesChange={onFilesChange(form)}
              onChangeList={onChangeList(form)}
              showDropArea={true}
              initialValues={initialValues}
              listFile={listFile}
              loading={isLoading || isUploading}
              disabledDownload={!canDownloadTemplate}
              disabledUpload={!canUploadFile}
            />
          </DivStyled>
        </Form>
      )}
    </Formik>
  );
};
