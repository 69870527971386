import React from 'react';
import { FormattedMessage } from 'react-intl';

import { TranslationKeys } from '../../../../../../translations';

import type { GetUnprocessedEventsCountQueryResultData } from '../../../../../../hooks';
import { useProductPensionSchemeSelectItems } from '../../../../../../hooks';

import type { UnprocessedEvent } from '../../../../../../types';
import { ProductPensionSchemeEnum } from '../../../../../../types';

import formatter from '../../../../../../utils/formatter';

import type {
  FilterableTableColumnType,
  FilterableTableRowChildMapperFunc,
  FilterableTableRowMapperFunc,
  TableCellHeaderSortOptions,
} from '../../../../../../components';
import { ActionLink, FilterableTable } from '../../../../../../components';

import { BoldValue } from './UnprocessedEventsTable.style';

enum TableColumns {
  Employer = 'employerName',
  Type = 'type',
  LatestRun = 'latestRun',
  NewEmployee = 'newEmployee',
  ContractUpdate = 'contractUpdate',
  EmployeeAddressUpdate = 'employee-address-updated',
  EmployeeInfoUpdate = 'employee-information-updated',
  EmployeePartTimePercentageUpdate = 'employee-part-time-percentage-updated',
  EmployeePartnerUpdate = 'employee-partner-updated',
  GrossWageUpdate = 'grossWageUpdate',
  EmployeeLeftCompany = 'employeeLeftCompany',
  NewPeriodicWage = 'new-periodic-wage',
  ReEmployed = 'reEmployed',
  Total = 'total',
}

type UnprocessedEventsTableProps = {
  isLoading?: boolean;
  unprocessedEvents: Array<UnprocessedEvent>;
  unprocessedEventsCount: GetUnprocessedEventsCountQueryResultData | undefined;
  onPaginationChange: (args: { page?: number; rowSize?: number }) => void;
  onSortChange?: (sortBy: Record<TableColumns, TableCellHeaderSortOptions>) => void;
  page: number;
  pageSize: number;
  totalCount: number;
  rowsPerPageOptions?: Array<number>;
};

export const UnprocessedEventsTable = ({
  isLoading,
  unprocessedEvents,
  unprocessedEventsCount,
  page,
  pageSize,
  totalCount,
  rowsPerPageOptions,
  onPaginationChange,
  onSortChange,
}: UnprocessedEventsTableProps) => {
  const { getProductSchemeDisplayNameByValue } = useProductPensionSchemeSelectItems();

  const tablePagination = React.useMemo(() => {
    return {
      page,
      pageSize,
      totalCount,
      rowsPerPageOptions,
    };
  }, [page, pageSize, rowsPerPageOptions, totalCount]);

  const grandTotal = React.useMemo(() => {
    return Object.values(unprocessedEventsCount || {}).reduce((acc, count) => acc + count, 0);
  }, [unprocessedEventsCount]);

  const tableHeaders = React.useMemo<Array<FilterableTableColumnType>>(() => {
    return [
      {
        name: TableColumns.Employer,
        title: <FormattedMessage id={TranslationKeys.employers_title} />,
        sortable: true,
      },
      {
        name: TableColumns.Type,
        title: <FormattedMessage id={TranslationKeys.global_type} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.LatestRun,
        title: <FormattedMessage id={TranslationKeys.connection_latestRun} />,
        textAlign: 'center',
      },
      {
        name: TableColumns.NewEmployee,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_newEmployee}
            values={{ count: unprocessedEventsCount?.['new-employee'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.ContractUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_contractUpdate}
            values={{ count: unprocessedEventsCount?.['contract-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.EmployeeAddressUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_employeeAddressUpdate}
            values={{ count: unprocessedEventsCount?.['employee-address-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.EmployeeInfoUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_infoUpdate}
            values={{ count: unprocessedEventsCount?.['employee-information-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.EmployeePartTimePercentageUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_partTimePercentageUpdate}
            values={{ count: unprocessedEventsCount?.['employee-part-time-percentage-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.EmployeePartnerUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_partnerUpdate}
            values={{ count: unprocessedEventsCount?.['employee-partner-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.GrossWageUpdate,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_grossWageUpdate}
            values={{ count: unprocessedEventsCount?.['gross-wage-updated'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.EmployeeLeftCompany,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_employeeLeftCompany}
            values={{ count: unprocessedEventsCount?.['employee-left-company'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.NewPeriodicWage,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_newPeriodicWage}
            values={{ count: unprocessedEventsCount?.['new-periodic-wage'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.ReEmployed,
        title: (
          <FormattedMessage
            id={TranslationKeys.dashboard_unprocessedEvents_col_reEmployed}
            values={{ count: unprocessedEventsCount?.['re-employed'] ?? 0 }}
          />
        ),
        textAlign: 'center',
      },
      {
        name: TableColumns.Total,
        title: (
          <FormattedMessage id={TranslationKeys.dashboard_unprocessedEvents_col_total} values={{ count: grandTotal }} />
        ),
        textAlign: 'center',
        sortable: true,
      },
    ];
  }, [grandTotal, unprocessedEventsCount]);

  const renderNumber = React.useCallback((value: number | undefined) => {
    return value ?? 0;
  }, []);

  const getTypeTranslation = React.useCallback(
    (type: string | null) => {
      if (type === ProductPensionSchemeEnum.Adequate) {
        return getProductSchemeDisplayNameByValue(ProductPensionSchemeEnum.Adequate);
      }
      if (type === ProductPensionSchemeEnum.Regular) {
        return getProductSchemeDisplayNameByValue(ProductPensionSchemeEnum.Regular);
      }

      return type;
    },
    [getProductSchemeDisplayNameByValue],
  );

  const tableRowMapper = React.useCallback<
    FilterableTableRowMapperFunc<UnprocessedEvent, UnprocessedEvent['connections'][number]>
  >(
    data => {
      return {
        data: {
          [TableColumns.Employer]: <ActionLink to={`/employers/${data.employerId}`}>{data.employerName}</ActionLink>,
          [TableColumns.Type]: <BoldValue>{'-'}</BoldValue>,
          [TableColumns.LatestRun]: <BoldValue>{'-'}</BoldValue>,
          [TableColumns.NewEmployee]: <BoldValue>{renderNumber(data.eventTypeTotals['new-employee'])}</BoldValue>,
          [TableColumns.ContractUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['contract-updated'])}</BoldValue>
          ),
          [TableColumns.EmployeeAddressUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['employee-address-updated'])}</BoldValue>
          ),
          [TableColumns.EmployeeInfoUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['employee-information-updated'])}</BoldValue>
          ),
          [TableColumns.EmployeePartTimePercentageUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['employee-part-time-percentage-updated'])}</BoldValue>
          ),
          [TableColumns.EmployeePartnerUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['employee-partner-updated'])}</BoldValue>
          ),
          [TableColumns.GrossWageUpdate]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['gross-wage-updated'])}</BoldValue>
          ),
          [TableColumns.EmployeeLeftCompany]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['employee-left-company'])}</BoldValue>
          ),
          [TableColumns.NewPeriodicWage]: (
            <BoldValue>{renderNumber(data.eventTypeTotals['new-periodic-wage'])}</BoldValue>
          ),
          [TableColumns.ReEmployed]: <BoldValue>{renderNumber(data.eventTypeTotals['re-employed'])}</BoldValue>,
          [TableColumns.Total]: <BoldValue>{renderNumber(data.total)}</BoldValue>,
          children: data.connections,
        },
      };
    },
    [renderNumber],
  );

  const rowChildMapper = React.useCallback<FilterableTableRowChildMapperFunc<UnprocessedEvent['connections'][number]>>(
    child => {
      return {
        data: {
          [TableColumns.Employer]: (
            <ActionLink to={`/employers/${child.employerId}/connections/${child.connectionId}`}>
              {child.connectionName}
            </ActionLink>
          ),
          [TableColumns.Type]: getTypeTranslation(child.connectionType),
          [TableColumns.LatestRun]: formatter.date(child.latestRun),
          [TableColumns.NewEmployee]: renderNumber(child.eventTypes['new-employee']),
          [TableColumns.ContractUpdate]: renderNumber(child.eventTypes['contract-updated']),
          [TableColumns.EmployeeAddressUpdate]: renderNumber(child.eventTypes['employee-address-updated']),
          [TableColumns.EmployeeInfoUpdate]: renderNumber(child.eventTypes['employee-information-updated']),
          [TableColumns.EmployeePartTimePercentageUpdate]: renderNumber(
            child.eventTypes['employee-part-time-percentage-updated'],
          ),
          [TableColumns.EmployeePartnerUpdate]: renderNumber(child.eventTypes['employee-partner-updated']),
          [TableColumns.GrossWageUpdate]: renderNumber(child.eventTypes['gross-wage-updated']),
          [TableColumns.EmployeeLeftCompany]: renderNumber(child.eventTypes['employee-left-company']),
          [TableColumns.NewPeriodicWage]: renderNumber(child.eventTypes['new-periodic-wage']),
          [TableColumns.ReEmployed]: renderNumber(child.eventTypes['re-employed']),
          [TableColumns.Total]: renderNumber(child.total),
        },
      };
    },
    [renderNumber, getTypeTranslation],
  );

  return (
    <FilterableTable<UnprocessedEvent, UnprocessedEvent['connections'][number]>
      loading={isLoading}
      columns={tableHeaders}
      rows={unprocessedEvents}
      rowMapper={tableRowMapper}
      rowChildMapper={rowChildMapper}
      pagination={tablePagination}
      onPaginationChange={onPaginationChange}
      onSortChange={onSortChange}
    />
  );
};
