import React from 'react';
import { Box } from '@mui/material';
import { ActionButton, HasAccessTo, SearchInput } from 'app/components';
import { CONNECTION } from 'app/common/Authorization/entities';
import { RUN } from 'app/common/Authorization/permissions';
import { TranslationKeys } from 'app/translations';
import { CAN_COMPARE, CAN_COMPARE_COMPLETED } from '../../../../../../../ConnectionDetailsPage/BatchesTab/constants';

type CompareBatchListHeaderProps = {
  isLoading?: boolean;
  onSearch: (inputValue: string) => void;
  searchQuery?: string;
  approveBatch: () => void;
  archiveBatch: () => void;
  markAsComplete: () => void;
  resendMutations: () => void;
  batchStatus: 'draft' | 'pending-insurer-data' | 'compared' | 'to-be-checked';
  selectedCount?: number;
};

export const CompareBatchListHeader = ({
  onSearch,
  searchQuery,
  approveBatch,
  archiveBatch,
  markAsComplete,
  resendMutations,
  batchStatus,
  selectedCount = 0,
  isLoading = false,
}: CompareBatchListHeaderProps) => {
  const canRunConnections = HasAccessTo(CONNECTION, RUN);
  const disabledApproveButton = isLoading || selectedCount === 0 || !canRunConnections;

  const onChangeSearchInput = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onSearch(event.target.value);
    },
    [onSearch],
  );

  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
      <SearchInput
        disabled={isLoading}
        onChange={onChangeSearchInput}
        initialSearchQuery={searchQuery}
        debounceTime={500}
      />

      {CAN_COMPARE.includes(batchStatus) && (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_archiveButton}
            disabled={disabledApproveButton}
            onClick={archiveBatch}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_approveButton}
            disabled={disabledApproveButton}
            onClick={approveBatch}
          />
        </Box>
      )}

      {CAN_COMPARE_COMPLETED.includes(batchStatus) && (
        <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={2}>
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_resendSelectedMutations}
            disabled={disabledApproveButton}
            onClick={resendMutations}
          />
          <ActionButton
            messageId={TranslationKeys.connection_processes_compareBatch_markAsComplete}
            disabled={false}
            onClick={markAsComplete}
          />
        </Box>
      )}
    </Box>
  );
};
