import { useCallback } from 'react';
import type { BooleanSchema, MixedSchema } from 'yup';
import * as Yup from 'yup';
import { ArraySchema, DateSchema, NumberSchema, Schema, StringSchema, ValidationError } from 'yup';

import type { Field } from '../../../types';

import { isEmpty, isNil, isNumeric, Regexes } from '../../../utils';

import type { LocaleOption } from '../../Intl';
import { useLocale } from '../../Intl';
import { locale as intl } from 'app/components/Intl/IntlWrapper';

export const getMonthsOptions = (locale: LocaleOption) => {
  return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(month => ({
    element: locale.locale.localize?.month(month),
    value: `${month}`,
  }));
};

const mapOptions = (options: Array<any>) =>
  options.map((option: any) => {
    if (typeof option === 'string') {
      return option;
    }
    return option?.value;
  });

const mapErrorHelperText = (locale: LocaleOption, options: Array<any>) =>
  options
    .map((option: any) => {
      if (typeof option === 'string') {
        return option;
      }

      const { element, value } = option;
      return element ?? locale.messages[value] ?? value;
    })
    .join(', ');

const buildMultiSelect = (locale: LocaleOption, field: Field) => {
  const selectSchema = Yup.array();

  if (field.required) {
    return selectSchema.required();
  }

  return selectSchema;
};

const buildSelect = (locale: LocaleOption, field: Field) => {
  const selectSchema = field.options?.multiple ? Yup.array() : Yup.mixed();

  if (field.options?.freeSolo) {
    return selectSchema;
  }

  if (field.options?.multiple) {
    return selectSchema;
  }

  let optionsArray = field.items || [];

  if (isEmpty(optionsArray)) {
    return selectSchema;
  }

  optionsArray = optionsArray.map(option => ({
    default: option.default ?? false,
    value: option.value,
    element: intl.messages[option.element] ?? option.element,
  }));

  const errorHelperText = mapErrorHelperText(locale, optionsArray);
  let possibleValues = mapOptions(optionsArray);

  if (selectSchema instanceof ArraySchema) {
    return selectSchema.oneOf(
      possibleValues,
      ValidationError.formatError(locale.validationMessages.mixed?.oneOf, { values: errorHelperText }),
    );
  }

  // @see https://github.com/jquense/yup/issues/104
  if (!field.required) {
    possibleValues = ['', null, undefined, ...possibleValues];
  }
  return selectSchema.oneOf(
    possibleValues,
    ValidationError.formatError(locale.validationMessages.mixed?.oneOf, { values: errorHelperText }),
  );
};

const getBaseSchema = (
  locale: LocaleOption,
  field: Field,
  defaultType: Field['type'],
):
  | DateSchema
  | NumberSchema
  | StringSchema
  | BooleanSchema
  | MixedSchema
  | Schema
  | ReturnType<typeof buildSelect>
  | ReturnType<typeof buildMultiSelect> => {
  switch (field.type ?? defaultType) {
    case 'multi-select':
      return buildMultiSelect(locale, field);
    case 'list':
    case 'select':
    case 'autocomplete':
      return buildSelect(locale, field);
    case 'month': {
      return buildSelect(locale, { ...field, items: getMonthsOptions(locale) });
    }
    case 'date':
      return Yup.date().transform((current, original) => (original === '' ? null : current));
    case 'checkbox':
    case 'boolean':
      return Yup.boolean();
    case 'tel':
    case 'number':
      return Yup.number();
    case 'email':
      return Yup.string().ensure().trim().max(255).email();
    case 'url':
      return Yup.string().ensure().trim().max(255).url();
    case 'password':
      return Yup.string().ensure().trim().max(255);
    case 'text':
      return Yup.string().ensure();
    default:
      return Yup.mixed();
  }
};

const buildSchema = (locale: LocaleOption, field: Field, defaultType: Field['type']): Schema => {
  if (field.loading) {
    return Yup.mixed();
  }

  if (field.schema instanceof Schema) {
    return field.schema;
  }

  let schema = getBaseSchema(locale, field, defaultType);

  if (field.required) {
    schema = schema.required();
    if (isNil(field.options?.min) && schema instanceof ArraySchema) {
      schema = schema.min(1);
    }
  } else {
    schema = schema.nullable().optional();
  }

  if (!isNil(field.options?.min) && isNumeric(field.options?.min)) {
    if (schema instanceof NumberSchema) {
      schema = schema.min(parseFloat(`${field.options?.min}`));
    } else if (schema instanceof StringSchema || schema instanceof ArraySchema) {
      schema = schema.min(parseInt(`${field.options?.min}`));
    } else if (schema instanceof DateSchema) {
      schema = schema.min(field.options?.min);
    }
  }

  if (!isNil(field.options?.max) && isNumeric(field.options?.max)) {
    if (schema instanceof NumberSchema) {
      schema = schema.max(parseFloat(`${field.options?.max}`));
    } else if (schema instanceof StringSchema || schema instanceof ArraySchema) {
      schema = schema.max(parseInt(`${field.options?.max}`));
    } else if (schema instanceof DateSchema) {
      schema = schema.max(field.options?.max);
    }
  }

  if (!isNil(field.options?.match) && schema instanceof StringSchema) {
    if (field.options?.match === 'hostOrIP') {
      schema = schema.matches(Regexes.HostOrIPExact);
    }
  }

  if (field.schema instanceof Function) {
    return field.schema(schema);
  }

  return schema;
};

export const useFieldValidator = (
  defaultType: Field['type'],
): ((value: any, field: Field) => Promise<string | undefined>) => {
  const locale = useLocale();
  return useCallback(
    (value, field) => {
      const schema = buildSchema(locale, field, defaultType);
      return schema
        .validate(value)
        .then(() => {
          return undefined;
        })
        .catch((error: Error) => {
          if (ValidationError.isError(error)) {
            if (error.inner) {
              if (error.inner.length === 0) {
                return error.message;
              }
              return error.inner[0].message;
            }
          }
          throw error;
        });
    },
    [defaultType, locale],
  );
};
